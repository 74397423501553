'use client';

import { useState } from 'react';
import { Spinner } from './Spinner';
import { sendGTMEvent } from '@next/third-parties/google';

export const LandingContactForm = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [sended, setSended] = useState(false);
	const [error, setError] = useState(false);

	const gtag_report_conversion = () => {
		if (typeof window.gtag === 'function') {
			window.gtag('event', 'conversion', {
				'send_to': 'AW-16577154782/u1JQCNah1r4ZEN6dzeA9'
			});
		}
	};

	const handleReset = () => {
		setName('');
		setEmail('');
		setPhone('');
		setMessage('');
		setIsLoading(false);
		setSended(false);
		setError(false);
	};

	return (
		<form
			className="contact-section__form"
			onSubmit={async (e) => {
				e.preventDefault();
				setIsLoading(true);
				const formData = {
					name: name,
					email: email,
					phone: phone,
					message: message
				};
				const res = await fetch('/api/send', {
					method: 'POST',
					body: JSON.stringify(formData),
					headers: {
						'Content-Type': 'application/json'
					}
				});
				setIsLoading(false);
				if (res.ok) {
					const data = await res.json();
					setSended(true);
					gtag_report_conversion();
				} else {
					setError(true);
					setIsLoading(false);
				}
			}}
			data-aos="fade-up"
			data-aos-offset="100"
			data-aos-easing="ease-out"
			data-aos-duration="300">
			<div className="input-container">
				<label htmlFor="name">
					Nombre<span>*</span>
				</label>
				<input
					value={name}
					onChange={(e) => setName(e.target.value)}
					type="text"
					name="name"
					id="name"
					maxLength={50}
					placeholder="Tu nombre"
					required
				/>
			</div>
			<div className="input-container">
				<label htmlFor="email">
					Correo electrónico<span>*</span>
				</label>
				<input
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					type="email"
					name="email"
					id="email"
					maxLength={100}
					placeholder="ejemplo@gmail.com"
					required
				/>
			</div>
			<div className="input-container">
				<label htmlFor="phone">Número de teléfono</label>
				<input
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					type="tel"
					name="phone"
					id="phone"
					maxLength={50}
					placeholder="+56 9 1234-5678"
				/>
			</div>
			<div className="input-container">
				<label htmlFor="message">
					Mensaje<span>*</span>
				</label>
				<textarea
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					name="message"
					id="message"
					maxLength={250}
					placeholder="Tu mensaje"
					rows={5}
					required
				/>
			</div>
			<button
				type="submit"
				className={`${error ? 'disabled' : ''} ${sended ? 'sended' : ''} ${
					isLoading ? 'sending' : ''
				}`}
				disabled={error || sended}>
				<span>
					{isLoading
						? 'Enviando'
						: sended
						? 'Correo enviado 🎉'
						: error
						? 'Ha ocurrido un error'
						: 'Enviar'}
				</span>
				{isLoading ? <Spinner /> : null}
			</button>
			{error ? (
				<p className="contact__errorMessage">
					Por favor comunicate con nosotros por{' '}
					<a href="https://wa.link/63oe8r" target="_blank">
						Whatsapp
					</a>
				</p>
			) : null}
			{sended ? (
				<button type="reset" onClick={handleReset} className="button-reset">
					Volver a enviar
				</button>
			) : null}
		</form>
	);
};
