'use client';
import React, { useState } from 'react';
import './styles.scss';

export const ReactThemeToggleButton: React.FC = () => {
	// Inicia siempre con el tema claro, ignorando cualquier estado guardado
	const [isDark, setIsDark] = useState<boolean>(false);

	function handleChange(): void {
		const newTheme = !isDark;
		setIsDark(newTheme);
		document.documentElement.setAttribute(
			'data-theme',
			newTheme ? 'dark' : 'light'
		);
		// No guardamos el tema en localStorage para evitar que se recuerde entre sesiones
	}

	// Aseguramos que el tema se establezca en claro en cada carga de página
	React.useEffect(() => {
		document.documentElement.setAttribute('data-theme', 'light');
	}, []);

	return (
		<label
			className={`theme-container ${isDark ? 'isDark' : 'isLight'}`}
			title={isDark ? 'Activate light mode' : 'Activate dark mode'}
			aria-label={isDark ? 'Activate light mode' : 'Activate dark mode'}>
			<input type="checkbox" checked={isDark} onChange={handleChange} />
			<div />
		</label>
	);
};
