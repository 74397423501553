'use client';
import { NavigationLink } from '@/models/cabecera';
import { useEffect, useRef, useState } from 'react';
import { Squash as Hamburger } from 'hamburger-react';
import { Logo } from './Logo';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Image from 'next/image';

interface Props {
	hash: string;
	navigation: NavigationLink[];
}

export const Menu = ({ hash, navigation }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef<HTMLDivElement>(null);
	const actualPathname = usePathname();

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		const header = document.querySelector('.header');
		if (actualPathname.includes('blog')) {
			header?.classList.add('filled');
		} else {
			header?.classList.remove('filled');
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [actualPathname]);
	useEffect(() => {
		const header = document.querySelector('.header');
		if (isOpen) header?.classList.add('open');
		else header?.classList.remove('open');
	}, [isOpen]);

	return (
		<div className="header__menu section-x" ref={menuRef}>
			<div className="header__mobile-buttons">
				<Hamburger
					toggled={isOpen}
					toggle={setIsOpen}
					size={48}
					rounded
					easing="ease-out"
					label={`${isOpen ? 'Cerrar' : 'Abrir'} menu`}
				/>
			</div>
			<Link href="/#inicio" aria-label="Inicio" tabIndex={-1}>
				<Logo hash={hash} />
			</Link>
			<nav className="header__navigation">
				<ul>
					{navigation.map((link) => (
						<li key={link.href}>
							<Link href={link.href} onClick={() => setIsOpen(false)}>
								<span className="heading">{link.texto}</span>
								<span className="description">{link.descripcion}</span>
							</Link>
						</li>
					))}
				</ul>
			</nav>
		</div>
	);
};
