import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["AOSComponent"] */ "/opt/build/repo/src/components/AOSComponent/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GtagLink"] */ "/opt/build/repo/src/components/fixedWhatsapp/GtagLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Menu"] */ "/opt/build/repo/src/components/header/Menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactThemeToggleButton"] */ "/opt/build/repo/src/components/reactThemeToggle/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LandingContactForm"] */ "/opt/build/repo/src/contactForm/landing/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GtagLink"] */ "/opt/build/repo/src/sections/hero/GtaLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToggleModalBtn"] */ "/opt/build/repo/src/sections/services/ToggleModalBtn.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/hero.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/howSection.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/services.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/about.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/features.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/contact.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/blog.scss");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/map.scss");
