'use client';

import { useState } from 'react';
import { ServiceModal } from './ServiceModal';
import Image from 'next/image';

interface Props {
	popupServicio: {
		titulo: string;
		descripcion: string;
		imagen: {
			data: {
				attributes: {
					hash: string;
					url: string;
				};
			};
		};
	};
}

export const ToggleModalBtn = ({ popupServicio }: Props) => {
	const [serviceModalOpen, setServiceModalOpen] = useState(false);

	return (
		<div className="toggle-modal-container">
			<button onClick={() => setServiceModalOpen(true)}>Leer más</button>
			{serviceModalOpen ? (
				<ServiceModal
					isOpen={serviceModalOpen}
					handleClose={() => setServiceModalOpen(!serviceModalOpen)}>
					<picture>
						<Image
							fill
							src={popupServicio.imagen.data.attributes.url}
							alt="Imagen del servicio"
						/>
					</picture>
					<div className="text-box">
						<h4>{popupServicio.titulo}</h4>
						<p>{popupServicio.descripcion}</p>
						<a
							href="#contacto"
							onClick={() => setServiceModalOpen(!serviceModalOpen)}>
							Me interesa este servicio
						</a>
					</div>
				</ServiceModal>
			) : null}
		</div>
	);
};
