import { ReactNode, useEffect } from 'react';
import { ReactPortal } from './ReactPortal';

interface Props {
	children: ReactNode;
	isOpen: boolean;
	handleClose: () => void;
}
export const ServiceModal = ({ children, isOpen, handleClose }: Props) => {
	useEffect(() => {
		const closeOnEscapeKey = (e: KeyboardEvent) =>
			e.key === 'Escape' ? handleClose() : null;
		document.body.addEventListener('keydown', closeOnEscapeKey);

		return () => {
			document.body.removeEventListener('keydown', closeOnEscapeKey);
		};
	}, [handleClose]);

	useEffect(() => {
		document.documentElement.style.overflowY = 'hidden';
		return (): void => {
			document.documentElement.style.overflowY = 'unset';
		};
	}, [isOpen, handleClose]);

	if (!isOpen) return null;

	return (
		<ReactPortal wrapperId="react-portal-modal-container">
			<>
				<div className="fixed top-0 left-0 w-screen h-screen z-[550] bg-colorLayoutTertiary opacity-50 "></div>
				<div className="service-modal">
					<button className="close-icon" onClick={handleClose}>
						<svg>
							<use href="/svgs/sprite.svg#close"></use>
						</svg>
					</button>
					{children}
					<button className="close-text" onClick={handleClose}>
						Cerrar
					</button>
				</div>
			</>
		</ReactPortal>
	);
};
