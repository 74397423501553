import { CldImage } from 'next-cloudinary';

export const Logo = ({ hash }: { hash: string }) => {
	return (
		<picture className="header__logo">
			<CldImage
				width={320}
				height={100}
				src={hash}
				alt="Logo"
				aspectRatio={3.2}
				priority
				fetchPriority="high"
			/>
		</picture>
	);
};
